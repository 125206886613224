const moment = require("moment");
const isFn = (fn, call) => {
  const valid = typeof fn == "function";
  if (valid && call) fn();

  return valid;
};

export default {
  data() {
    return {
      reservationScope: {},
    };
  },
  methods: {
    initReserve(
      startApiFn,
      stopApiFn,
      warnFn,
      entityName,
      updateTimeout = 60000
    ) {
      if (!isFn(startApiFn) || !isFn(stopApiFn) || !updateTimeout > 49) {
        isFn(warnFn, true);
        return;
      }

      this.reservationScope = {
        startApiFn,
        stopApiFn,
        warnFn,
        updateTimeout,
        entityName,
        updateInterval: null,
      };

      this.startReserve();
    },
    startReserve() {
      if (isFn(this.reservationScope.startApiFn)) {
        const warn = (msg, callStopFn) => {
          if (msg) {
            this.$toastr.e(msg);
          }

          this.setLoad();
          this.stopReserve(true, callStopFn);
        };

        const sendFn = () =>
          this.reservationScope
            .startApiFn()
            .then((res) => {
              if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                if (this.isObject(res.data?.result?.reservationDetails)) {
                  const reservation = res.data.result.reservationDetails;
                  if (reservation.editedByRole?.id === this.getUserRole(true)) {
                    return;
                  }

                  const time = reservation.editedAt
                    ? `la ora <b>${moment(reservation.editedAt).format(
                        "HH:mm"
                      )}</b>`
                    : "";
                  const user = reservation.editedByRole?.user;
                  const userRole =
                    this.changeRoleView(reservation.editedByRole?.role) || "";

                  let toastrMsg = "";
                  const { entityName } = this.reservationScope;

                  if (this.isObject(user))
                    toastrMsg = `${entityName} este în proces de editare de către utilizatorul <b>${this.readUserObject(
                      user
                    )} ${userRole ? ` - ${userRole}` : ""}</b> ${time || ""}.`;
                  else if (userRole)
                    toastrMsg = `${entityName} este în proces de editare de către un <b>${userRole}</b> ${time ||
                      ""}.`;
                  else
                    toastrMsg = `${entityName} este în proces de editare de către un utilizator ${time ||
                      ""}.`;

                  if (toastrMsg) {
                    this.$toastr.Add({
                      msg: toastrMsg,
                      timeout: 10000,
                      type: "warning",
                    });
                  }

                  warn(false, false);
                } else {
                  // console.log('Succes reserved!')
                }
              } else {
                warn("Încercați mai târziu.");
              }
              this.setLoad();
            })
            .catch(warn);

        sendFn();
        this.reservationScope.updateInterval = setInterval(
          sendFn,
          this.reservationScope.updateTimeout || 60000
        );
      }
    },
    stopReserve(callWarnFn, callStopFn) {
      const reservationScope = { ...this.reservationScope };
      this.reservationScope = {};
      clearInterval(reservationScope.updateInterval);
      if (callWarnFn) isFn(reservationScope.warnFn, true);

      if (callStopFn !== false && isFn(reservationScope.stopApiFn)) {
        const error = (msg) => {
          this.$toastr.e(msg || "Încercați mai târziu.");
        };

        reservationScope
          .stopApiFn()
          .then((res) => {
            if (!this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              error();
            }
          })
          .catch(error);
      }
    },
  },
  beforeDestroy() {
    this.stopReserve();
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      this.stopReserve();
    }, {once: true})
  }
};
